import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BottomBannerLeft from "../components/bottom-banner-left";
import SvgFanDarkSo from "../components/svg/fan-dark-svg-so";
import SvgFanLightSo from "../components/svg/fan-light-svg-so";
import SvgFanLightNoPink from "../components/svg/fan-light-no-pink";
import { GatsbyImage } from "gatsby-plugin-image";
import useOnScreen from "../components/use-on-screen";
import gsap from "gsap";
import HubspotForm from "react-hubspot-form";
const SponsorshipOpportunitiesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			LFImg: wpMediaItem(title: { eq: "Lightning-Fibre-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ESCImg: wpMediaItem(
				title: { eq: "Enterprise Logo for Dark Backgrounds Carnival" }
			) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			SHImg: wpMediaItem(title: { eq: "Seahaven-FM" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			MAImg: wpMediaItem(title: { eq: "Media Attention" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			CImg: wpMediaItem(title: { eq: "Custard" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			BfImg: wpMediaItem(title: { eq: "Bournefree" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			KImg: wpMediaItem(title: { eq: "Kumon-Updated" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			TBEImg: wpMediaItem(title: { eq: "Eastbourne Community Partner logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			BGImg: wpMediaItem(title: { eq: "Birchwood-Group" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			beaconImg: wpMediaItem(title: { eq: "Beacon Logo Blue" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ditsyImg: wpMediaItem(title: { eq: "Ditzy-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			emImg: wpMediaItem(title: { eq: "Elite-Medical-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			bfImg: wpMediaItem(title: { eq: "Birchwood-Group-Square-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			efImg: wpMediaItem(title: { eq: "EF-Logos_EF" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			mgImg: wpMediaItem(title: { eq: "Eastbourne MG Logo 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			kumImg: wpMediaItem(title: { eq: "Kumon-logo-new" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			signImg: wpMediaItem(title: { eq: "Signtek-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			rjmImg: wpMediaItem(title: { eq: "rjm-digital-logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			IDImg: wpMediaItem(title: { eq: "Identity_Logo_RedSquare" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			logoImg: wpMediaItem(title: { eq: "Logo-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			mightyImg: wpMediaItem(title: { eq: "High & Mighty Scaffold" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sdfImg: wpMediaItem(title: { eq: "SDF Business card front" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const LFImg = data.LFImg?.localFile.childImageSharp.gatsbyImageData;
	const logoImg = data.logoImg?.localFile.childImageSharp.gatsbyImageData;
	const ESCImg = data.ESCImg?.localFile.childImageSharp.gatsbyImageData;
	const SHImg = data.SHImg?.localFile.childImageSharp.gatsbyImageData;
	const MAImg = data.MAImg?.localFile.childImageSharp.gatsbyImageData;
	const CImg = data.CImg?.localFile.childImageSharp.gatsbyImageData;
	const BfImg = data.BfImg?.localFile.childImageSharp.gatsbyImageData;
	const KImg = data.KImg?.localFile.childImageSharp.gatsbyImageData;
	const TBEImg = data.TBEImg?.localFile.childImageSharp.gatsbyImageData;
	const BGImg = data.BGImg?.localFile.childImageSharp.gatsbyImageData;
	const beaconImg = data.beaconImg?.localFile.childImageSharp.gatsbyImageData;
	const ditsyImg = data.ditsyImg?.localFile.childImageSharp.gatsbyImageData;
	const emImg = data.emImg?.localFile.childImageSharp.gatsbyImageData;
	const bfImg = data.bfImg?.localFile.childImageSharp.gatsbyImageData;
	const efImg = data.efImg?.localFile.childImageSharp.gatsbyImageData;
	const mgImg = data.mgImg?.localFile.childImageSharp.gatsbyImageData;
	const kumImg = data.kumImg?.localFile.childImageSharp.gatsbyImageData;
	const signImg = data.signImg?.localFile.childImageSharp.gatsbyImageData;
	const rjmImg = data.rjmImg?.localFile.childImageSharp.gatsbyImageData;
	const IDImg = data.IDImg?.localFile.childImageSharp.gatsbyImageData;
	const mightyImg = data.mightyImg?.localFile.childImageSharp.gatsbyImageData;
	const sdfImg = data.sdfImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Sponsorship Opportunities",
				item: {
					url: `${siteUrl}/sponsorship-opportunities`,
					id: `${siteUrl}/sponsorship-opportunities`,
				},
			},
		],
	};

	const refso = useRef();

	const onScreenso = useOnScreen(refso, "300px");

	useEffect(() => {
		if (onScreenso === true) {
			gsap.to(".card-1", {
				transform: "scale(1)",
				duration: 1,
			});
			gsap.to(".card-2", {
				transform: "scale(1)",
				duration: 1,
				delay: 0.5,
			});
			gsap.to(".card-3", {
				transform: "scale(1)",
				duration: 1,
				delay: 1,
			});
			gsap.to(".card-4", {
				transform: "scale(1)",
				duration: 1,
				delay: 1.5,
			});
			gsap.to(".card-5", {
				transform: "scale(1)",
				duration: 1,
				delay: 2,
			});
		}
	}, [onScreenso]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Sponsorship Opportunities Page | Eastbourne Carnival"
				description="To make Carnival a great success, we rely on funds raised from bucket collection (50% of which goes to local charities and non-profits), and sponsorship from local businesses."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/sponsorship-opportunities`,
					title: "Sponsorship Opportunities Page | Eastbourne Carnival",
					description:
						"To make Carnival a great success, we rely on funds raised from bucket collection (50% of which goes to local charities and non-profits), and sponsorship from local businesses.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>
			{/* <section>
        <Container>
          <Row>
            <Col>
              {" "}
              <h1 className=" display-4 pb-4 pb-lg-6 pt-lg-7 pt-5 text-center carnival-normal text-secondary">
                Our Sponsors
              </h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Row>
                <h2 className="text-secondary shortstack fs-2">
                  Platinum Sponsors
                </h2>
              </Row>
              <Row className="justify-content-center gx-md-5 pt-4 align-items-center">
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.lightningfibre.co.uk/"
                  >
                    {" "}
                    <GatsbyImage image={LFImg} alt={data.LFImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.ef.co.uk/ilsd/schools/eastbourne/"
                  >
                    <GatsbyImage image={efImg} alt={data.efImg?.altText} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-8">
              <Row>
                <h2 className="text-secondary shortstack fs-2">
                  Gold Sponsors
                </h2>
              </Row>
              <Row className="justify-content-center gx-md-5 pt-4 align-items-center">
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.birchwoodgroup.co.uk/"
                  >
                    <GatsbyImage image={bfImg} alt={data.bfImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.eastbournemg.co.uk/"
                  >
                    <GatsbyImage image={mgImg} alt={data.mgImg?.altText} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="text-center  mt-8">
              <Row>
                <h2 className="text-secondary shortstack fs-2">
                  Event Sponsors
                </h2>
              </Row>
              <Row className="justify-content-center gy-4 gy-md-5 gy-lg-0 gx-md-5 pt-4 align-items-center">
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://enterprise-centre.org/"
                  >
                    <GatsbyImage image={ESCImg} alt={data.ESCImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.kumon.co.uk/eastbourne/"
                  >
                    <GatsbyImage image={KImg} alt={data.KImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://thebeaconeastbourne.com/"
                  >
                    <GatsbyImage
                      image={beaconImg}
                      alt={data.beaconImg?.altText}
                    />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://identityglobal.com/"
                  >
                    <GatsbyImage image={IDImg} alt={data.IDImg?.altText} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="text-center  mt-8">
              <Row>
                <h2 className="text-secondary shortstack fs-2">
                  Our Event partners
                </h2>
              </Row>
              <Row className="justify-content-center g-5 pt-4 align-items-center">
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://mediaattention.co.uk/"
                  >
                    <GatsbyImage image={MAImg} alt={data.MAImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://bournefreemag.co.uk/"
                  >
                    <GatsbyImage image={BfImg} alt={data.BfImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.custard-design.co.uk/Custard_-_marketing_design_e-media_print.html"
                  >
                    {" "}
                    <GatsbyImage image={CImg} alt={data.CImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.seahavenfm.com/"
                  >
                    {" "}
                    <GatsbyImage image={SHImg} alt={data.SHImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.thebestof.co.uk/local/eastbourne/"
                  >
                    {" "}
                    <GatsbyImage image={TBEImg} alt={data.TBEImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://elitemedical.uk/"
                  >
                    {" "}
                    <GatsbyImage image={emImg} alt={data.emImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://signtek.co.uk/"
                  >
                    {" "}
                    <GatsbyImage image={signImg} alt={data.signImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.rjm.digital/"
                  >
                    {" "}
                    <GatsbyImage image={rjmImg} alt={data.rjmImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://www.sdfltd.co.uk/"
                  >
                    {" "}
                    <GatsbyImage image={sdfImg} alt={data.sdfImg?.altText} />
                  </a>
                </Col>
                <Col xs={6} lg={4}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://highandmightyscaffold.com/"
                  >
                    {" "}
                    <GatsbyImage
                      image={mightyImg}
                      alt={data.mightyImg?.altText}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section> */}
			<section className="pt-5 pt-lg-8  pb-5  position-relative">
				<Container>
					<Row>
						<Col className="text-center">
							<GatsbyImage
								style={{ maxWidth: "200px" }}
								image={logoImg}
								alt={data.logoImg?.altText}
							/>
						</Col>
					</Row>
					<Row className="justify-content-center pb-7">
						<Col lg={8}>
							<h1 className=" text-center display-4 pb-4 carnival-normal text-secondary">
								Sponsorship Opportunities
							</h1>

							<p>
								To make Carnival a great success, we rely on funds raised from
								bucket collection (50% of which goes to local charities and
								non-profits), and sponsorship from local businesses.
							</p>
							<p>
								We offer four levels of sponsorship; Headline, Platinum, Gold
								and Event Sponsor – all of which provide great exposure to your
								business, and ensure we can make Eastbourne Carnival a dynamic,
								vibrant and enjoyable event.
							</p>
							<p>
								Additionally, we invite local media partners to work with us to
								promote the carnival far and wide!
							</p>
						</Col>
					</Row>
					<Row ref={refso} className="h-100 justify-content-center gx-3 gy-3">
						<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
							<div
								style={{
									minHeight: "100%",
									borderRadius: "10px",
									transform: "scale(0)",
									backgroundColor: "#44164D",
								}}
								className=" card-1 position-relative text-white py-5 px-3 "
							>
								<h2 className="fs-2 carnival-normal text-center">
									Headline Sponsor - £5000
								</h2>
								<hr
									className="bg-white mb-4"
									style={{ opacity: 1, height: "2px" }}
								/>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Most Prominent position on Carnival Website Sponsor Page
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Use of Eastbourne Carnival logo with statement ‘Headline
										Sponsors of Eastbourne Carnival 2024’
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Logo on Flyers, Posters and Advertising
									</p>
								</span>

								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Banners x 4 on Eastbourne Seafront on Carnival Day
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										8 x VIP Passes to the Bandstand
									</p>
								</span>

								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on our Social Media Channels from Mid February
										until the Carnival (5 posts per month)
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Namecheck in Press Releases
									</p>
								</span>
								{/* <div style={{ height: "60px" }}></div>
                <div>
                  <p className="shortstack position-absolute bottom-0 pb-4 start-50 translate-middle-x text-center fs-1">
                    £2,000
                  </p>
                </div> */}
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={6} xl={4}>
							<div
								style={{
									minHeight: "100%",
									borderRadius: "10px",
									transform: "scale(0)",
								}}
								className="bg-secondary card-2 position-relative text-white py-5 px-3 "
							>
								<h2 className="fs-2 carnival-normal text-center">
									Platinum Sponsors - £2000
								</h2>
								<hr
									className="bg-white mb-4"
									style={{ opacity: 1, height: "2px" }}
								/>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on website as ‘Platinum Sponsors’
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Use of Eastbourne Carnival logo with statement ‘Platinum
										Sponsors of Eastbourne Carnival 2024’
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Logo on Flyers, Posters and Advertising
									</p>
								</span>

								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Banners x 2 on Eastbourne Seafront on Carnival Day
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										6 x VIP Passes to the Bandstand
									</p>
								</span>

								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on our Social Media Channels from March until the
										Carnival (4 posts per month)
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Namecheck in Press Releases
									</p>
								</span>
								{/* <div style={{ height: "60px" }}></div>
                <div>
                  <p className="shortstack position-absolute bottom-0 pb-4 start-50 translate-middle-x text-center fs-1">
                    £2,000
                  </p>
                </div> */}
							</div>
						</Col>
						<Col className=" " style={{ minHeight: "100%" }} lg={6} xl={4}>
							<div
								style={{
									minHeight: "100%",
									borderRadius: "10px",
									transform: "scale(0)",
								}}
								className="bg-primary position-relative card-3 text-white py-5 px-3 "
							>
								<h2 className="fs-2 carnival-normal text-center">
									Gold Sponsors - £1000
								</h2>
								<hr
									className="bg-white mb-4"
									style={{ opacity: 1, height: "2px" }}
								/>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanDarkSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on website as ‘Gold Sponsors’
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanDarkSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Use of Eastbourne Carnival logo with statement ‘Gold
										Sponsors of Eastbourne Carnival 2024’
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanDarkSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Logo on Flyers, Posters and Advertising
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanDarkSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Banners x 2 on Eastbourne Seafront on Carnival Day
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanDarkSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										4 x VIP Passes to the Bandstand
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanDarkSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on our Social Media Channels from March until the
										Carnival (2 posts per month)
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanDarkSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Namecheck in Press Releases
									</p>
								</span>
								{/* <div style={{ height: "60px" }}></div>
                <div>
                  <p className="shortstack px-0 position-absolute bottom-0 pb-4 start-50 w-100 translate-middle-x text-center fs-1">
                    £1,000
                  </p>
                </div> */}
							</div>
						</Col>{" "}
						<Col
							className=" mb-lg-0"
							style={{ minHeight: "100%" }}
							lg={6}
							xl={4}
						>
							<div
								style={{
									minHeight: "100%",
									borderRadius: "10px",
									transform: "scale(0)",
								}}
								className="bg-pink position-relative card-4 text-white py-5 px-3 "
							>
								<h2 className="fs-2 carnival-normal text-center">
									Event Sponsors - £500
								</h2>
								<hr
									className="bg-white mb-4"
									style={{ opacity: 1, height: "2px" }}
								/>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on website as ‘Event Sponsors’
									</p>
								</span>

								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Use of Eastbourne Carnival logo with statement ‘Event
										Sponsors of Eastbourne Carnival 2024’
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Banners x 2 on Eastbourne Seafront on Carnival Day
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										4 x VIP Passes to the Bandstand
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on our Social Media Channels from March until the
										Carnival (2 posts per month)
									</p>
								</span>
								{/* <div style={{ height: "60px" }}></div>
                <div>
                  <p className="shortstack px-0 position-absolute bottom-0 pb-4 start-50 w-100 translate-middle-x text-center fs-1">
                    £500
                  </p>
                </div> */}
							</div>
						</Col>
						<Col
							className="mb-5 mb-lg-0"
							style={{ minHeight: "100%" }}
							lg={6}
							xl={4}
						>
							<div
								style={{
									minHeight: "100%",
									borderRadius: "10px",
									transform: "scale(0)",
									backgroundColor: "#3AB548",
								}}
								className=" position-relative card-5 text-white py-5 px-3 "
							>
								<h2 className="fs-2 carnival-normal text-center">
									Carnival Supporters - £150
								</h2>
								<hr
									className="bg-white mb-4"
									style={{ opacity: 1, height: "2px" }}
								/>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Use of Eastbourne Carnival logo with statement ‘Supporter of
										Eastbourne Carnival 2024’
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										2 x VIP Passes to the Bandstand
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightNoPink
											style={{ width: "60px", height: "auto" }}
										/>
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block pb-0 mb-0"
									>
										Featured on our Social Media Channels – One shout out post.
									</p>
								</span>

								{/* <div style={{ height: "60px" }}></div>
                <div>
                  <p className="shortstack px-0 position-absolute bottom-0 pb-4 start-50 w-100 translate-middle-x text-center fs-1">
                    £500
                  </p>
                </div> */}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-md-7 ">
				<Container>
					{" "}
					<Row>
						<Col>
							<h1 className=" text-center display-4 pb-4 carnival-normal text-secondary">
								Sponsor Enquiry Form
							</h1>{" "}
							<HubspotForm
								region="eu1"
								portalId="25477959"
								formId="b0f631bc-fca6-4894-a71f-4e2841b69f26"
								onSubmit={() => console.log("Submit!")}
								onReady={(form) => console.log("Form ready!")}
								loading={<div>Loading...</div>}
							/>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <BottomBannerLeft
				mainBg="bg-lightBlue"
				position="top--20"
				content={
					<Row>
						<Col className="py-5 py-md-0">
							<h2 className="text-white text-center mt-md-5  pt-md-10 fs-1 shortstack">
								GET IN TOUCH TO REGISTER YOUR INTEREST
							</h2>
							<div className="d-flex pt-5 justify-content-center align-items-center">
								<Button
									variant="outline-white"
									className="fs-5 py-3  px-4 jost-normal"
									as={Link}
									to="/contact-us"
								>
									CONTACT US PAGE
								</Button>
							</div>
						</Col>
					</Row>
				}
			/> */}
		</Layout>
	);
};

export default SponsorshipOpportunitiesPage;
